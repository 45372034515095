import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Hallinta() {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const SALASANA = 'paakokki';

    const handleLogin = () => {
        if (password === SALASANA) {
            navigate('/ruokalajit');
        } else {
            alert('Väärä salasana!');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="mb-4">Kirjaudu hallintaan</h1>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Syötä salasana"
                className="border p-2 mb-4"
            />
            <button onClick={handleLogin} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg">
                Kirjaudu
            </button>
        </div>
    );
}

export default Hallinta;
