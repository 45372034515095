import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from './config';
import { useLocation } from 'react-router-dom';

function Ruokalajit() {
    const [ruokalajit, setRuokalajit] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(Date.now()); // Lisää tämä rivi
    const location = useLocation();

    useEffect(() => {
        fetch(`${config.BACKEND_URL}/api/ruokalajit`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => setRuokalajit(data))
            .catch(error => console.error('There was a problem with the fetch operation:', error.message));
    }, [lastUpdate, location]);    

    const haeJaLisaaRuokalajit = () => {
        fetch(`${config.BACKEND_URL}/api/hae_ruokalajit`, {
            method: 'POST'
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("Virhe ruokalajien haussa ja lisäämisessä");
        })
        .then(data => {
            console.log("Ruokalajit haettu ja lisätty onnistuneesti!");
            // Hae päivitetyt ruokalajit ja päivitä komponentin tila
            return fetch(`${config.BACKEND_URL}/api/ruokalajit`);
        })
        .then(response => response.json())
        .then(data => {
            setRuokalajit(data);
        })
        .catch(error => {
            console.error(error);
        });
    };    
    
    const poistaRuokalaji = (nimi) => {
        fetch(`${config.BACKEND_URL}/api/poista/${nimi}`, {
            method: 'POST'
        })
        .then(response => {
            if (!response.ok) {
                // Jos palvelin palauttaa virhekoodin, heitä virhe
                throw new Error(`Virhe ruokalajin poistamisessa: ${response.statusText}`);
            }
            return response.json();
        })
        .then(data => {
            // Tarkista, että palvelin palautti odotetun vastauksen
            if (data && data.success) {
                setRuokalajit(prevRuokalajit => prevRuokalajit.filter(ruokalaji => ruokalaji.nimi !== nimi));
            } else {
                throw new Error("Virhe ruokalajin poistamisessa: Väärä palvelimen vastaus");
            }
        })
        .catch(error => {
            console.error(error);
        });
    };

    return (
    <div className="p-4">
        <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mb-4 inline-block">
            Palaa etusivulle
        </Link>
        <Link to="/lisaa" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg mb-4 ml-4 inline-block">
            Lisää ruokalaji
        </Link>

        <h1 className="text-2xl font-bold mb-4">Ruokalajit</h1>
        <table className="w-full border-collapse border border-gray-400">
            <thead>
                <tr className="bg-gray-200">
                    <th className="border border-gray-400 px-4 py-2">Nimi</th>
                    <th className="border border-gray-400 px-4 py-2 hidden md:table-cell">Pääraaka-aine</th>
                    <th className="border border-gray-400 px-4 py-2 hidden md:table-cell">Lisuke</th>
                    <th className="border border-gray-400 px-4 py-2">Toiminnot</th>
                </tr>
            </thead>
            <tbody>
                {ruokalajit.map((ruokalaji, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                        <td className="border border-gray-400 px-4 py-2">{ruokalaji.nimi}</td>
                        <td className="border border-gray-400 px-4 py-2 hidden md:table-cell">{ruokalaji.paaraaka_aine}</td>
                        <td className="border border-gray-400 px-4 py-2 hidden md:table-cell">{ruokalaji.lisuke}</td>
                        <td className="border border-gray-400 px-4 py-2">
                            <Link to={{
                                pathname: `/muokkaa/${ruokalaji.nimi}`,
                                state: { setLastUpdate }
                            }} className="text-blue-500 hover:underline mr-2">
                                Muokkaa
                            </Link>
                            <button onClick={() => {
                                if (window.confirm('Haluatko varmasti poistaa tämän ruokalajin?')) {
                                    poistaRuokalaji(ruokalaji.nimi);
                                }
                            }} className="text-red-500 hover:underline">
                                Poista
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <button onClick={haeJaLisaaRuokalajit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mt-4">
            Hae ja lisää ruokalajit
        </button>
    </div>
);
}

export default Ruokalajit;
