import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import config from './config';

function KuluvanViikonLista() {
    const [lounaslista, setLounaslista] = useState([]);

    useEffect(() => {
        fetch(`${config.BACKEND_URL}/api/kuluvan_viikon_lista`)
            .then(response => response.json())
            .then(data => {
                const groupedData = data.reduce((acc, item) => {
                    if (!acc[item.viikonpaiva]) {
                        acc[item.viikonpaiva] = [];
                    }
                    acc[item.viikonpaiva].push(item);
                    return acc;
                }, {});
                setLounaslista(groupedData);
            });
    }, []);

    return (
        <div className="flex flex-col items-center justify-center py-8">
            <h1 className="text-2xl font-bold mb-6">Kuluvan viikon lounaslista</h1>
            {Object.entries(lounaslista).map(([viikonpaiva, ruoat]) => (
                <div key={viikonpaiva} className="mb-6 text-center w-3/4">
                    <h2 className="text-xl font-semibold mb-4">{viikonpaiva}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {ruoat.map(ruoka => (
                            <div key={ruoka.nimi} className="border p-4 rounded-md">
                                <p className="text-lg mb-2">{ruoka.nimi}</p>
                                <p className="text-gray-600">{ruoka.lisuke}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg flex items-center">
                <FaArrowLeft className="mr-2" />
                Takaisin etusivulle
            </Link>
        </div>
    );
}

export default KuluvanViikonLista;
