import React, { useState, useEffect } from 'react';
import config from './config';
import { TbArrowsRandom } from 'react-icons/tb';
import { Link } from 'react-router-dom'; // 

function Paivallislista() {
    const [paivallislista, setPaivallislista] = useState([]);
    const viikonpaivat = ["Maanantai", "Tiistai", "Keskiviikko", "Torstai", "Perjantai"];

    useEffect(() => {
        fetch(`${config.BACKEND_URL}/api/paivallislista`)
            .then(response => response.json())
            .then(data => setPaivallislista(data))
            .catch(error => console.error("Virhe päivällislistan haussa:", error));
    }, []);

    const arvoUusi = (index) => {
    fetch(`${config.BACKEND_URL}/api/arvo_uusi/${index}`, {
        method: 'POST'
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('API-pyyntö epäonnistui');
        }
        return response.json();
    })
    .then(data => {
    if (data.error) {
        console.error(data.error);
    } else {
        // Päivitä vain kyseinen päivä paivallislista-tilassa
        const updatedList = [...paivallislista];
        updatedList[index] = data[index];
        setPaivallislista(updatedList);
    }
})
    .catch(error => console.error("Virhe uuden ruokalajin arpomisessa:", error));
};

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
            <h1 className="text-2xl font-bold mb-6">Ensi viikon päivällislista</h1>
            <table className="bg-white shadow-md rounded-lg overflow-hidden max-w-lg w-full">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="py-2 px-4 text-left hidden md:table-cell">Päivä</th>
                        <th className="py-2 px-4 text-left md:hidden">Pv</th>
                        <th className="py-2 px-4 text-left">Ruokalaji</th>
                        <th className="py-2 px-4 text-left">Arvo uusi</th>
                    </tr>
                </thead>
                <tbody>
                    {paivallislista.map((ruokalaji, index) => (
                        <tr key={index} className="border-t">
                            <td className="py-2 px-4 hidden md:table-cell">{viikonpaivat[index]}</td>
                            <td className="py-2 px-4 md:hidden">{viikonpaivat[index].slice(0, 2).toLowerCase()}</td>
                            <td className="py-2 px-4">{ruokalaji.nimi}</td>
                            <td className="py-2 px-4">
                                <button 
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                    onClick={() => arvoUusi(index)}
                                >
                                    <TbArrowsRandom />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Palaa etusivulle
            </Link>
        </div>
    );
}

export default Paivallislista;
