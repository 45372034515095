import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import config from './config';

const MuokkaaRuokalaji = ({ initialData }) => {
    const [data, setData] = useState(initialData);
    const { nimi } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const setLastUpdateFromProps = location.state && location.state.setLastUpdate;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave();
        navigate('/ruokalajit');
    };

    const handleSave = () => {
        const editedData = {
            nimi: data.nimi,
            paaraaka_aine: data.paaraaka_aine,
            lisuke: data.lisuke,
        };

        const decodedName = decodeURIComponent(nimi);
        fetch(`${config.BACKEND_URL}/api/ruokalajit/${decodedName}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(editedData),
        })
            .then((response) => {
                if (response.ok) {
                    if (typeof setLastUpdateFromProps === "function") {
                        setLastUpdateFromProps(Date.now());
                    }
                    navigate('/ruokalajit');
                } else {
                    throw new Error('Tallennuksessa tapahtui virhe');
                }
            })
            .catch((error) => {
                console.error('Virhe tallennuksessa:', error);
            });
    };

    const handleCancel = () => {
        navigate('/ruokalajit');
    };

    return (
        <div className="bg-white p-4 rounded-md shadow-md">
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nimi">
                        Ruokalajin nimi
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="nimi"
                        type="text"
                        name="nimi"
                        value={data.nimi}
                        onChange={handleChange} // Varmista, että tätä käytetään
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="paaraaka_aine">
                        Pääraaka-aine
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="paaraaka_aine"
                        type="text"
                        name="paaraaka_aine"
                        value={data.paaraaka_aine}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lisuke">
                        Lisuke
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="lisuke"
                        type="text"
                        name="lisuke"
                        value={data.lisuke}
                        onChange={handleChange}
                    />
                </div>

                <div className="flex justify-between">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Tallenna
                    </button>
                    <button
                        type="button"
                        className="bg-red-500 hover.bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleCancel}
                    >
                        Peruuta
                    </button>
                </div>
            </form>
        </div>
    );
};

export default MuokkaaRuokalaji;
