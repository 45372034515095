import React from 'react';
import { Link } from 'react-router-dom';
import { FaCogs } from 'react-icons/fa';
import version from './Version';

function Home() {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <header>
                <h1 className="text-4xl font-bold mb-4">GORDONI</h1>
            </header>
            <div className="space-y-4">
                <Link to="/kuluvan_viikon_lista" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg block">
                    Kuluvan viikon lista
                </Link>
                <Link to="/seuraavan_viikon_lounas" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg block">
                    Ensi viikon lista
                </Link>
                <Link to="/paivallislista" className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg block">
                    Ensi viikon kotiruokaehdotus
                </Link>
            </div>
            <div className="mt-4">
                <Link to="/hallinta" className="text-4xl text-gray-500">
                    <FaCogs />
                </Link>
            </div>
            <p class="text-xs font-medium text-gray-900 dark:text-white">ver. {version}</p>
        </div>
    );
}

export default Home;
