import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from './config';

function LisaaRuokalaji() {
    const [nimi, setNimi] = useState('');
    const [paaraaka_aine, setPaaraakaAine] = useState('');
    const [lisuke, setLisuke] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch(`${config.BACKEND_URL}/api/ruokalajit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ nimi, paaraaka_aine, lisuke })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                navigate('/ruokalajit');    
            } else {
                console.error('Virhe ruokalajin lisäämisessä:', data.message);
                toast.error("Virhe ruokalajin lisäämisessä: Ruokalaji on jo olemassa");
            }
        })
        .catch(error => {
            console.error('Virhe ruokalajin lisäämisessä:', error);
            toast.error("Virhe ruokalajin lisäämisessä: " + error.message);
        });    
    };
    const handleCancel = () => {
        navigate('/ruokalajit');
    };
    return (
        <div className="p-4">
            <ToastContainer />
            <h1 className="text-2xl font-bold mb-4">Lisää uusi ruokalaji</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-sm font-bold mb-2" htmlFor="nimi">
                        Nimi
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="nimi" type="text" value={nimi} onChange={(e) => setNimi(e.target.value)} required />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-bold mb-2" htmlFor="paaraaka_aine">
                        Pääraaka-aine
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="paaraaka_aine" type="text" value={paaraaka_aine} onChange={(e) => setPaaraakaAine(e.target.value)} required />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-bold mb-2" htmlFor="lisuke">
                        Lisuke
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="lisuke" type="text" value={lisuke} onChange={(e) => setLisuke(e.target.value)} required />
                </div>
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Lisää
                </button>
                <button type="button" onClick={handleCancel} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                    Peruuta
                </button>
            </form>
        </div>
    );
}

export default LisaaRuokalaji;
