import React, { useState, useEffect } from 'react'; // Lisää nämä tuonnit
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import config from './config';
import Home from './Home';
import Ruokalajit from './Ruokalajit';
import MuokkaaRuokalaji from './MuokkaaRuokalaji';
import LisaaRuokalaji from './LisaaRuokalaji';
import KuluvanViikonLista from './KuluvanViikonLista';
import SeuraavanViikonLista from './SeuraavanViikonLista';
import Paivallislista from './Paivallislista';
import Hallinta from './Hallinta';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ruokalajit" element={<Ruokalajit />} />
          <Route path="/kuluvan_viikon_lista" element={<KuluvanViikonLista />} /> {/* Päivitetty tämä rivi */}
          <Route path="/seuraavan_viikon_lounas" element={<SeuraavanViikonLista />} />
          <Route path="/paivallislista" element={<Paivallislista />} />
          <Route path="/muokkaa/:nimi" element={<MuokkaaRuokalajiWrapper />} />
          <Route path="/lisaa" element={<LisaaRuokalaji />} />
          <Route path="/hallinta" element={<Hallinta />} />
        </Routes>
      </div>
    </Router>
  );
}

function MuokkaaRuokalajiWrapper() {
    const [selectedRuokalaji, setSelectedRuokalaji] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { nimi } = useParams();
  
    useEffect(() => {
      if (nimi) {
        fetch(`${config.BACKEND_URL}/api/ruokalajit/${nimi}`)
          .then(response => response.json())
          .then(data => {
            setSelectedRuokalaji(data);
            setIsLoading(false);
          })
          .catch(error => console.error("Virhe ruokalajin haussa:", error));
      }
    }, [nimi]);
  
    return isLoading ? "Ladataan..." : <MuokkaaRuokalaji initialData={selectedRuokalaji} />;
}

export default App;
